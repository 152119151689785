export default async function connectMetamask() {
  try {
    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });
    return { publicAddress: accounts[0] };
  } catch (err) {
    let errorMessage;
    if (err.code === 4001) {
      errorMessage = "user.error.reject.metamask";
    } else {
      errorMessage = err;
    }
    return { errorMessage };
  }
}
