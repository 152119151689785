import React from 'react';
import {
  Box,
  Text,
  Link,
  Flex,
  Button,
  Circle,
  Image,
  useDisclosure,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import logoutOut from 'assets/images/logout.png';
import ModalLeaveGroup from 'components/ModalLeaveGroup';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';

export default function CardJoined({ group }) {
  const { t } = useMarkdownTranslations();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box p={4} borderWidth="1px" borderRadius="md">
      <Flex
        align="left"
        borderBottom="2px solid"
        borderColor="gray.200"
        marginBottom={6}
        flexDirection="column"
      >
        <Flex
          textAlign="left"
          mb={2}
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
        >
          <Circle
            size="60px"
            className="group-identification-circle"
            color="white"
            mr={4}
          >
            {group?.attributes?.title?.charAt(0)?.toUpperCase()}
          </Circle>
          <Box>
            <Text className="group-card-header">
              {group?.attributes?.title}
            </Text>
            <Link className="group-card-link">
              {group?.attributes?.url || 'https://www.testing.com'}
            </Link>
            <Text className="group-card-subheader">
              {t('btn.members_amount', {
                amount: group?.attributes?.['user-count'],
              })}
            </Text>
          </Box>
        </Flex>
        <Flex justifyContent="flex-start" mb={4}>
          <Button className="info-button" w="auto">
            {t('btn.decisions.active_amount', {
              amount: group?.attributes?.['decisions-count'],
            })}
          </Button>
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center" mb={4} mt={4} gap={2}>
        <Button
          _hover={{ textDecoration: 'none' }}
          textDecoration={'none'}
          flex="0.93"
          className="main-button"
          as={RouterLink}
          to={`/g/${group?.attributes?.slug}/decisions`}
        >
          {t('btn.decisions')}
        </Button>
        <Button flex="0.07" className="secondary-button" onClick={onOpen}>
          <Image src={logoutOut} />
        </Button>
      </Flex>
      <Flex justify="space-between" align="center">
        <Button
          width="100%"
          className="main-button"
          textAlign="left"
          justifyContent="flex-start"
          _hover={{ textDecoration: 'none' }}
          textDecoration={'none'}
          as={RouterLink}
          to={`/g/${group?.attributes?.slug}`}
        >
          {t('btn.view_info')}
        </Button>{' '}
      </Flex>
      <ModalLeaveGroup isOpen={isOpen} onClose={onClose} group={group} />
    </Box>
  );
}
