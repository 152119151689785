const inputStyles = {
  _focus: {
    borderColor: "var(--secondaryInputTextColor)",
    border: "1px",
    boxShadow: "none",
    outline: "none",
  },
  border: "1px solid #D4D4D4 !important",
  outline: "none",
  bgColor: "var(--INPUT-BACKGROUND)",
  color: "var(--secondaryInputTextColor)",
};

const selectWithSearchStyles = {
  styles: { menuPortal: (base) => ({ ...base, zIndex: 1 }) },
};

const chakraStyles = {
  container: (provided) => ({
    ...provided,
    h: 10,
    borderRadius: "0px !important",
    zIndex: 3,
    border: 0,
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    background: "transparent",
    border: 0,
    padding: 2,
    h: 10,
    borderRadius: "0px !important",
    with: "30%",
    zIndex: 3,
  }),

  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    position: "relative",
    border: 0,
  }),

  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
    zIndex: 3,
    position: "relative",
  }),

  control: (provided) => ({
    ...provided,
    ...inputStyles,
    zIndex: 3,
    position: "relative",
  }),
  option: (provided) => ({
    ...provided,
    ...inputStyles,
    bgColor: "var(--selectBackgroundMenuColor)",
    _hover: { border: 0 },
    _focus: { border: 0 },
    border: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    ...inputStyles,
    bgColor: "var(--selectBackgroundMenuColor)",
  }),
};

export { selectWithSearchStyles, chakraStyles };
