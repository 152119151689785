import React, { useState, useEffect } from "react";
import { Grid, Flex, Button } from "@chakra-ui/react";
import LeftArrow from "images/leftArrow";
import RightArrow from "images/rightArrow";
import CardInvited from "components/GroupAsAdmin/CardInvited";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";

export default function InvitedGroups({ groups, search, sortBy }) {
  const { t } = useMarkdownTranslations();
  const listOfPagesSizes = [6, 8, 10, 15, 20, 50];
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(listOfPagesSizes[0]);

  useEffect(() => {
    setPageIndex(0);
  }, [pageSize]);

  const filteredGroups = groups
    .filter((group) =>
      group?.attributes?.title?.toLowerCase().includes(search.toLowerCase()),
    )
    .sort((a, b) => {
      if (sortBy === "title") {
        return a.attributes.title.localeCompare(b.attributes.title);
      }
      // Add more sorting logic based on sortBy
      return 0;
    });

  const paginatedGroups = filteredGroups.slice(
    pageIndex * pageSize,
    (pageIndex + 1) * pageSize,
  );

  const totalPages = Math.ceil(filteredGroups.length / pageSize);

  const getPaginationButtons = () => {
    const buttons = [];
    for (let i = 0; i < totalPages; i++) {
      buttons.push(
        <Button
          key={i}
          color={pageIndex === i ? "var(--MAIN-TEXT)" : "var(--MAIN-GREY)"}
          bgColor={
            pageIndex === i ? "var(--MAIN-GREY)" : "var(--CLEAR-BACKGROUND)"
          }
          _hover={
            pageIndex === i ? "var(--MAIN-GREY)" : "var(--CLEAR-BACKGROUND)"
          }
          onClick={() => setPageIndex(i)}
        >
          {i + 1}
        </Button>,
      );
    }
    return buttons;
  };

  const previousPage = () => {
    if (pageIndex > 0) setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (pageIndex < totalPages - 1) setPageIndex(pageIndex + 1);
  };

  return (
    <>
      <Grid className="grid-hosted-hosted-decisions-page" gap={4}>
        {paginatedGroups.map((group, i) => (
          <CardInvited key={i} group={group} />
        ))}
      </Grid>
      <Flex
        className="outer-flex-hosted-decisions-page"
        role="region"
        aria-label={t("accessibility.pagination.arrows")}
        mt={6}
        justify="center"
        align="center"
        gap={2}
      >
        <Button
          className="button-arrow-page-template-list"
          onClick={previousPage}
          isDisabled={pageIndex <= 0}
        >
          <LeftArrow active={pageIndex > 0} />
        </Button>
        <Flex className="flex-pagination-buttons-hosted-decisions-page">
          {getPaginationButtons()}
        </Flex>
        <Button
          className="button-arrow-page-template-list"
          onClick={nextPage}
          isDisabled={pageIndex >= totalPages - 1}
        >
          <RightArrow active={pageIndex < totalPages - 1} />
        </Button>
      </Flex>
    </>
  );
}
