import React, { useState, useEffect } from 'react';
import {
  Box,
  Input,
  Text,
  Select,
  Button,
  Flex,
  InputGroup,
  InputRightElement,
  Image,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import searchIcon from '../../assets/images/search.png';
import ModalCreateGroup from 'components/GroupAsAdmin/ModalCreateGroup';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import JoinedGroups from 'components/JoinedGroups';
import HostedGroups from 'components/HostedGroups';
import InvitedGroups from 'components/InvitedGroups';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';

export default function MyGroups() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { groupsHosted, groupsJoined, groupsInvitations } =
    useCurrentGroupsContext();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('none');
  const { t } = useMarkdownTranslations();

  // React Router hooks
  const navigate = useNavigate(); // Updated hook
  const location = useLocation();

  // Mapping tab names to indices
  const tabNameToIndex = {
    joined: 0,
    hosting: 1,
    invited: 2,
  };

  const indexToTabName = {
    0: 'joined',
    1: 'hosting',
    2: 'invited',
  };

  // Get current tab from URL
  const pathParts = location.pathname.split('/');
  const currentTabName = pathParts[pathParts.length - 1];
  const [tabIndex, setTabIndex] = useState(tabNameToIndex[currentTabName] || 0);

  // Open modal if URL is /user/groups/create
  useEffect(() => {
    if (location.pathname === '/user/groups/create') {
      onOpen();
    }
  }, [location.pathname, onOpen]);

  // Update tabIndex when URL changes
  useEffect(() => {
    const currentTab = tabNameToIndex[currentTabName];
    if (currentTab !== undefined && currentTab !== tabIndex) {
      setTabIndex(currentTab);
    }
  }, [currentTabName, tabIndex, tabNameToIndex]);

  // Handle tab change
  const handleTabsChange = (index) => {
    setTabIndex(index);
    const tabName = indexToTabName[index];
    navigate(`/user/groups/${tabName}`); // Updated navigation
  };

  const listOfSorts = [
    { label: t('decision.sort.by'), value: 'none' },
    { label: t('decision.sort.title'), value: 'title' },
    { label: t('decision.sort.url'), value: 'url' },
  ];

  const handleModalClose = async () => {
    onClose();
    //await fetchGroups();
    navigate('/user/groups/hosting');
  };

  return (
    <>
      <ModalCreateGroup isOpen={isOpen} onClose={handleModalClose} />
      <Box className="main-box-hosted-decisions-page">
        <Box className="body-box-hosted-decisions-page">
          <Flex justify="space-between" align="center" mb={4}>
            <Box>
              <Text fontSize="xl" fontWeight="bold">
                Groups
              </Text>
              <Text>See and manage all the groups that you belong to.</Text>
            </Box>
            <Button
              className="main-button"
              onClick={() => navigate('/user/groups/create')}
            >
              Create New Group
            </Button>
          </Flex>
          <Box borderBottom="1px" borderColor="gray.200" mb={4} />
          <Tabs
            index={tabIndex}
            onChange={handleTabsChange}
            variant="unstyled"
            mt={6}
          >
            <TabList className="tab-list-hosted-decisions-page" mb={8} w="30%">
              <Tab
                className="tab-groups"
                _selected={{
                  borderBottom: '3px solid',
                  borderColor: 'var(--MAIN-GREY)',
                }}
                _focus={{ outline: 'none' }}
                flex="1"
              >
                {t('group.tabs.joined')}
              </Tab>
              <Tab
                className="tab-groups"
                _selected={{
                  borderBottom: '3px solid',
                  borderColor: 'var(--MAIN-GREY)',
                }}
                _focus={{ outline: 'none' }}
                flex="1"
              >
                {t('group.tabs.hosting')}
              </Tab>
              <Tab
                className="tab-groups"
                _selected={{
                  borderBottom: '3px solid',
                  borderColor: 'var(--MAIN-GREY)',
                }}
                _focus={{ outline: 'none' }}
                flex="1"
              >
                {t('group.tabs.invited')}
              </Tab>
            </TabList>
            <Flex className="flex-participate-participate-page" gap={4}>
              <Box className="first-box-participate-page" flex="0 0 88%">
                <InputGroup>
                  <InputRightElement>
                    <Image
                      src={searchIcon}
                      className="input-right-element-image-create-decision-page"
                      alt={t('input.placeholder.search')}
                    />
                  </InputRightElement>
                  <Input
                    className="input-participate-page"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={t('input.placeholder.search')}
                  />
                </InputGroup>
              </Box>
              <Box flex="0 0 10%">
                <InputGroup>
                  <Select
                    value={sortBy}
                    onChange={(e) => setSortBy(e.target.value)}
                    className="select-participate-page"
                  >
                    {listOfSorts.map((currentSort, i) => (
                      <option key={i} value={currentSort.value}>
                        {currentSort.label}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </Box>
            </Flex>
            <TabPanels>
              <TabPanel p={0}>
                <JoinedGroups
                  groups={groupsJoined}
                  search={search}
                  sortBy={sortBy}
                />
              </TabPanel>
              <TabPanel p={0}>
                <HostedGroups
                  groups={groupsHosted}
                  search={search}
                  sortBy={sortBy}
                />
              </TabPanel>
              <TabPanel p={0}>
                <InvitedGroups
                  groups={groupsInvitations}
                  search={search}
                  sortBy={sortBy}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </>
  );
}
