import { Button, Box, Center, Spinner } from '@chakra-ui/react';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import RegisterFields from 'components/RegisterFields';
import { useState } from 'react';

const JoinWithoutAccount = () => {
  const { currentGroup } = useCurrentGroupsContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useMarkdownTranslations();

  if (!currentGroup?.attributes) return null;

  return (
    <>
      <RegisterFields />
      <Box>
        <Center>
          <Button className="accept-btn">
            {isLoading ? <Spinner /> : t('authentication.register')}
          </Button>
        </Center>
      </Box>
    </>
  );
};

export default JoinWithoutAccount;
