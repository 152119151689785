import { useReducer } from "react";
import { groupsInitState, groupsReducer } from "features/groups/reducer";
import apiGroups from "api/groups";
import { GROUPS } from "features/groups/actions";
import { mergeRolesByPosition } from "utils";

export default function useCurrentGroups() {
  const [state, dispatch] = useReducer(groupsReducer, groupsInitState);

  const setDataOfCurrentGroup = (group) => {
    dispatch({
      type: GROUPS.CURRENT_GROUP,
      payload: group,
    });
  };

  const fetchGroups = async () => {
    try {
      const responseHosted = await apiGroups.getHostedGroups();
      const responseJoined = await apiGroups.getJoinedGroups();
      const responseAllGroups = await apiGroups.getAllGroups();

      const hostedGroups = mergeRolesByPosition(responseHosted);
      const joinedGroups = mergeRolesByPosition(responseJoined);
      const allGroups = mergeRolesByPosition(responseAllGroups);

      dispatch({
        type: GROUPS.GROUPS_HOSTED,
        payload: hostedGroups,
      });

      dispatch({
        type: GROUPS.GROUPS_JOINED,
        payload: joinedGroups,
      });

      dispatch({
        type: GROUPS.GROUPS_DATA,
        payload: allGroups,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.GROUPS_HOSTED,
        payload: [],
      });

      dispatch({
        type: GROUPS.GROUPS_JOINED,
        payload: [],
      });

      dispatch({
        type: GROUPS.GROUPS_DATA,
        payload: [],
      });
    }
  };

  const fetchGroupsInvitations = async () => {
    try {
      const response = await apiGroups.getGroupsInvitations();

      const invitations = mergeRolesByPosition(response);

      dispatch({
        type: GROUPS.GROUPS_INVITATIONS,
        payload: invitations,
      });
    } catch (error) {
      console.error("Error fetching group invitations:", error);

      dispatch({
        type: GROUPS.GROUPS_INVITATIONS,
        payload: [],
      });
    }
  };

  const fetchDecisions = async (groupId) => {
    try {
      const { data } = await apiGroups.getGroupDecisions(groupId);
      dispatch({
        type: GROUPS.GROUPS_DECISIONS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.GROUPS_DECISIONS,
        payload: {
          decisions: [],
        },
      });
    }
  };

  const fetchGroupBySlug = async (groupSlug) => {
    try {
      const { data } = await apiGroups.getGroupBySlug(groupSlug);
      dispatch({
        type: GROUPS.CURRENT_GROUP,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.CURRENT_GROUP,
        payload: [],
      });
    }
  };

  const fetchInvitesOfGroup = async (groupId) => {
    try {
      const { data } = await apiGroups.getInvitesOfGroup(groupId);
      dispatch({
        type: GROUPS.INVITES_OF_GROUP,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.INVITES_OF_GROUP,
        payload: [],
      });
    }
  };

  const fetchUsersOfGroup = async (groupId) => {
    try {
      const response = await apiGroups.getUsersOfGroup(groupId);

      const { included, data } = response;

      const mergedData = data.map((groupUser) => {
        const userId = groupUser.relationships.user.data.id;
        const user = included.find(
          (item) => item.type === "user" && item.id === userId,
        );
        return {
          ...groupUser,
          attributes: {
            ...groupUser.attributes,
            ...user.attributes,
          },
        };
      });

      dispatch({
        type: GROUPS.USERS_OF_GROUP,
        payload: mergedData,
      });
    } catch (error) {
      dispatch({
        type: GROUPS.USERS_OF_GROUP,
        payload: [],
      });
    }
  };

  const fetchGroupsAndInvitations = async () => {
    try {
      await fetchGroups();
      await fetchGroupsInvitations();
    } catch (error) {
      console.error("Error fetching groups and invitations:", error);
    }
  };

  return {
    ...state,
    fetchGroups,
    fetchGroupsInvitations,
    fetchDecisions,
    fetchGroupsAndInvitations,
    setDataOfCurrentGroup,
    fetchInvitesOfGroup,
    fetchUsersOfGroup,
    fetchGroupBySlug
  };
}
