import React from "react";
import {
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Button,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import "./styles.css";
import successImage from "../../assets/images/success.png";

const SuccessModal = ({ isOpen, onClose, text }) => {
  const { t } = useMarkdownTranslations();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent className="modal-content-success-modal" size="sm">
        <ModalBody>
          <Image
            src={successImage}
            className="success-images-success-modal"
            alt="success"
          />
          <Text role="heading" aria-level="1">
            {text}
          </Text>
          <Button className="button-success-modal" onClick={onClose}>
            {t("btn.close")}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
