import React from "react";
import { useState, useEffect } from "react";
import { Box, Grid, Text, Button, Flex } from "@chakra-ui/react";
import { useCurrentGroupsContext } from "features/groups/contexts/groups-provider";
import GroupsCardDecision from "components/GroupsCardDecision";
import LeftArrow from "images/leftArrow";
import RightArrow from "images/rightArrow";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";

export default function GroupsDecisions({ search, sortBy, status, isAdmin }) {
  const { decisionGroups } = useCurrentGroupsContext();
  const [listOfDecisions, setListOfDecisions] = useState(decisionGroups);
  const { t } = useMarkdownTranslations();
  const [pageIndex, setPageIndex] = useState(0);
  const pageSize = 6;
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    try {
      let filtered = decisionGroups.filter((decision) => {
        const searchLower = (search || "").toLowerCase();
        return decision.attributes.title.toLowerCase().includes(searchLower);
      });

      if (status !== "All") {
        filtered = filtered.filter((decision) => {
          return (
            decision?.attributes?.["current-mode"]?.toLowerCase() ===
            status.toLowerCase()
          );
        });
      }

      if (sortBy && sortBy !== "none") {
        filtered.sort((a, b) => {
          switch (sortBy) {
            case "title":
              return a.attributes.title.localeCompare(b.attributes.title);
            case "url":
              return (a.links.visit || "").localeCompare(
                b.attributes.url || "",
              );
            default:
              return 0;
          }
        });
      }

      setListOfDecisions(filtered);
      setPageIndex(0);
    } catch (error) {
      console.error("Error filtering and sorting decisions:", error);
    }
  }, [decisionGroups, search, sortBy, status]);

  const getLowerNumber = (numberA, numberB) => {
    return numberA < numberB ? numberA : numberB;
  };

  const previousPage = () => {
    if (pageIndex <= 0) return;
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (listOfDecisions.length <= (pageIndex + 1) * pageSize) return;
    setPageIndex(pageIndex + 1);
  };

  const getPaginationButtons = (pagesNumber) => {
    const buttonList = [];
    for (let i = 0; i < pagesNumber; i++) {
      buttonList.push(
        <Button
          key={i}
          className="button-change-page-decision-copy-list"
          color={pageIndex === i ? "var(--MAIN-TEXT)" : "var(--MAIN-GREY)"}
          bgColor={
            pageIndex === i ? "var(--MAIN-GREY)" : "var(--CLEAR-BACKGROUND)"
          }
          onClick={() => setPageIndex(i)}
        >
          {i + 1}
        </Button>,
      );
    }
    return buttonList;
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box className="main-box-hosted-decisions-page">
      <Grid className="grid-hosted-hosted-decisions-page" gap={3}>
        {listOfDecisions.map((currentDecision, i) => (
          <GroupsCardDecision
            key={i}
            currentDecision={currentDecision}
            isAdmin={isAdmin}
          />
        ))}
      </Grid>
      {windowWidth > 768 ? (
        <Flex
          className="outer-flex-hosted-decisions-page"
          role="region"
          aria-label={t("accessibility.pagination.arrows")}
        >
          <Flex className="column-flex-hosted-decisions-page">
            <Text className="showing-text-hosted-decisions-page">
              {t("pagination.show_pagination_decisions", {
                from: listOfDecisions.length > 0 ? pageIndex * pageSize + 1 : 0,
                to: getLowerNumber(
                  listOfDecisions.length,
                  (pageIndex + 1) * pageSize,
                ),
                total: listOfDecisions.length,
              })}
            </Text>
          </Flex>
          <Flex className="flex-pagination-hosted-decisions-page">
            <Button
              className="button-arrow-page-hosted-decisions-page"
              cursor={pageIndex > 0 ? "pointer" : "not-allowed"}
              onClick={previousPage}
            >
              <LeftArrow active={pageIndex > 0} />
            </Button>
            <Flex className="flex-pagination-buttons-hosted-decisions-page">
              {getPaginationButtons(
                Math.ceil(listOfDecisions.length / pageSize),
              )}
            </Flex>
            <Button
              onClick={nextPage}
              className="button-arrow-page-hosted-decisions-page"
              cursor={
                pageIndex < Math.ceil(listOfDecisions.length / pageSize) - 1
                  ? "pointer"
                  : "not-allowed"
              }
            >
              <RightArrow
                active={
                  pageIndex < Math.ceil(listOfDecisions.length / pageSize) - 1
                }
              />
            </Button>
          </Flex>
          <Box></Box>
        </Flex>
      ) : (
        <Flex className="responsive-bottom-flex-hosted-decisions-page">
          <Flex
            onClick={previousPage}
            className="flex-change-page-hosted-decisions-page"
            opacity={pageIndex > 0 ? 1 : 0.84}
            _hover={{ cursor: pageIndex > 0 ? "pointer" : "not-allowed" }}
          >
            <Button
              className="button-arrow-page-hosted-decisions-page"
              cursor={pageIndex > 0 ? "pointer" : "not-allowed"}
            >
              <LeftArrow active={pageIndex > 0} />
            </Button>
          </Flex>
          <Flex className="flex-pagination-buttons-hosted-decisions-page">
            {getPaginationButtons(Math.ceil(listOfDecisions.length / pageSize))}
          </Flex>
          <Flex
            onClick={nextPage}
            className="flex-change-page-hosted-decisions-page"
            opacity={
              pageIndex < Math.ceil(listOfDecisions.length / pageSize) - 1
                ? 1
                : 0.84
            }
            _hover={{
              cursor:
                pageIndex < Math.ceil(listOfDecisions.length / pageSize) - 1
                  ? "pointer"
                  : "not-allowed",
            }}
          >
            <Button
              className="button-arrow-page-hosted-decisions-page"
              cursor={
                pageIndex < listOfDecisions.length - 1
                  ? "pointer"
                  : "not-allowed"
              }
            >
              <RightArrow active={pageIndex < listOfDecisions.length - 1} />
            </Button>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
