import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  Circle,
  Text,
  Box,
  Link,
} from '@chakra-ui/react';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import groupsApi from 'api/groups';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';

export default function AcceptInvitationModal({ isOpen, onClose, group }) {
  const { t } = useMarkdownTranslations();
  const { fetchGroupsAndInvitations, fetchGroupsInvitations } =
    useCurrentGroupsContext();

  const acceptInvitation = async (groupId) => {
    try {
      await groupsApi.acceptGroupInvite(groupId);
      await fetchGroupsAndInvitations();
    } catch (error) {
      //todo: handle error
    } finally {
      onClose();
    }
  };

  const declineInvitation = async (groupId) => {
    try {
      await groupsApi.declineGroupInvite(groupId);
      await fetchGroupsInvitations();
    } catch (error) {
      //todo: handle error
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent borderRadius="16px" p={4} maxWidth="70%" width="70%">
        <ModalHeader>
          <Flex
            textAlign="left"
            mb={2}
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            <Circle
              size="60px"
              className="group-identification-circle"
              color="white"
              mr={4}
            >
              {group?.attributes?.title?.charAt(0)?.toUpperCase()}
            </Circle>
            <Box>
              <Text className="group-card-header">
                {group?.attributes?.title}
              </Text>
              <Link className="group-card-link">
                {group?.attributes?.url || 'https://www.testing.com'}
              </Link>
            </Box>
          </Flex>
        </ModalHeader>
        <ModalBody>
          <Text>{group?.attributes?.info}</Text>
        </ModalBody>
        <ModalFooter>
          <Button className="secondary-button" mr={3} onClick={onClose}>
            {t('btn.go_back')}
          </Button>
          <Button
            className="main-button"
            mr={3}
            onClick={() => acceptInvitation(group.id)}
          >
            {t('btn.accept_invitation')}
          </Button>
          <Button
            className="secondary-button"
            onClick={() => declineInvitation(group.id)}
          >
            {t('btn.decline_invitation')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
