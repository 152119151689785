import React from "react";
import useCurrentPayments from "features/payments/hooks/useCurrentPayments";

const PaymentsContext = React.createContext();

const PaymentsProvider = ({ children }) => {
  const currentPaymentsState = useCurrentPayments();

  return (
    <PaymentsContext.Provider value={currentPaymentsState}>
      {children}
    </PaymentsContext.Provider>
  );
};

const useCurrentPaymentsContext = () => {
  const context = React.useContext(PaymentsContext);
  if (context === undefined) {
    throw new Error(
      "currentPaymentsState must be used within a PaymentsProvider",
    );
  }
  return context;
};

export { PaymentsProvider, useCurrentPaymentsContext };
