import { DECISIONS } from "features/decisions/actions";

export const decisionsInitState = {
  tableDecisionsParticipantingData: [],
  tableDecisionsHostingData: [],
  tableDecisionsInvitedToParticipateData: [],
  tableDecisionsInvitedToHostData: [],
  decisionsTemplates: [],
  createDecisionData: {},
  editDecisionUrl: null,
  dataOfDecisionsAlreadyCalled: false,
};

/* eslint-disable no-fallthrough */
export const decisionsReducer = (state = decisionsInitState, action) => {
  switch (action.type) {
    case DECISIONS.DECISIONS_TEMPLATES:
      return {
        ...state,
        decisionsTemplates: action.payload,
      };
    case DECISIONS.TABLE_DECISIONS_PARTICIPATING:
      return {
        ...state,
        tableDecisionsParticipantingData: action.payload,
      };
    case DECISIONS.TABLE_DECISIONS_INVITED_TO_PARTICIPATE:
      return {
        ...state,
        tableDecisionsInvitedToParticipateData: action.payload,
      };
    case DECISIONS.TABLE_DECISIONS_HOSTING:
      return {
        ...state,
        tableDecisionsHostingData: action.payload,
      };
    case DECISIONS.TABLE_DECISIONS_INVITED_TO_HOST:
      return {
        ...state,
        tableDecisionsInvitedToHostData: action.payload,
      };
    case DECISIONS.CREATE_DECISION_DATA:
      return {
        ...state,
        createDecisionData: action.payload,
      };
    case DECISIONS.EDIT_DECISION_URL:
      return {
        ...state,
        editDecisionUrl: action.payload,
      };
    case DECISIONS.DATA_OF_DECISIONS_ALREADY_CALLED:
      return {
        ...state,
        dataOfDecisionsAlreadyCalled: action.payload,
      };
    default:
      return state;
  }
};
