import { useState, useEffect } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  Box,
  FormControl,
  Center,
  Grid,
  Image,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import userApi from "api/auth";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import "./styles.css";
import LoadingScreen from "components/LoadingScreen";
import { getErrorMessage } from "utils";
import politySideImage from "../../assets/images/polity_side.png";

const RecoverPasswordForm = ({ setWasMailSent }) => {
  const { t } = useMarkdownTranslations();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const sendRecoverPasswordLink = () => {
    if (!email) return;
    const data = { email };
    setLoading(true);
    userApi
      .requestPasswordReset({ user: data })
      .then(() => {
        setLoading(false);
        setWasMailSent(true);
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        e?.data?.errors?.forEach((currentError) => {
          setErrorMessage(getErrorMessage(currentError.detail));
        });
      });
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setError(false);
  }, [email]);

  return (
    <>
      <LoadingScreen loading={loading} />
      <Flex className="main-flex-recover-password-form" height="100vh">
        <Grid className="grid-recover-password-form">
          {windowWidth > 768 && (
            <Image
              src={politySideImage}
              className="side-image-recover-password-form"
              minHeight="100vh"
              maxHeight="100vh"
            />
          )}
          <Stack
            className="main-stack-recover-password-form"
            minHeight="100vh"
            maxHeight="100vh"
            justify="center"
            padding={8}
          >
            <Box className="register-text-recover-password-form">
              {t("authentication.password.forgot")}
            </Box>
            <Box className="welcome-text-recover-password-form">
              {t("company.welcome")}
            </Box>
            <Box>
              <Stack
                className="secondary-stack-recover-password-form"
                role="form"
                aria-label={t(`accessibility.authentication.login_form`)}
              >
                <FormControl className="form-control-recover-password-form">
                  <Center>
                    <InputGroup>
                      <InputLeftElement
                        className="input-left-element-recover-password-form"
                        children={<FontAwesomeIcon icon="fa-solid fa-at" />}
                      />
                      <Input
                        placeholder={t("authentication.email_address")}
                        type="email"
                        aria-labelledby={t("participant.email")}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={"input-recover-password-form"}
                      />
                    </InputGroup>
                    {error && (
                      <Text className="error-text-recover-password-form">
                        {errorMessage}
                      </Text>
                    )}
                  </Center>
                </FormControl>
                <Box className="button-box-recover-password-form">
                  <Center>
                    <Button
                      className="login-button-recover-password-form"
                      onClick={() => sendRecoverPasswordLink()}
                    >
                      {t("btn.send_email")}
                    </Button>
                  </Center>
                </Box>
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Flex>
    </>
  );
};

export default RecoverPasswordForm;
