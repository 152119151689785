import React from 'react';
import { Box, Text, Link, Flex, Circle } from '@chakra-ui/react';
import useMarkdownTranslations from '../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';

export default function GroupHeader({ showMembers }) {
  const { t } = useMarkdownTranslations();
  const { currentGroup } = useCurrentGroupsContext();

  if (!currentGroup) return null;
  return (
    <Flex
      textAlign="left"
      mb={4}
      justifyContent="flex-start"
      alignItems="center"
      width="100%"
    >
      <Flex
        textAlign="left"
        mb={2}
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Circle
          size="60px"
          className="group-identification-circle"
          color="white"
          mr={4}
        >
          {currentGroup.attributes.title.charAt(0)?.toUpperCase()}
        </Circle>
        <Box>
          <Text className="group-card-header">
            {currentGroup.attributes.title}
          </Text>
          <Link
            className="group-card-link"
            href="https://www.testing.com"
            target="_blank"
          >
            {currentGroup.attributes.url || 'https://www.testing.com'}
          </Link>
          {showMembers && (
            <Text className="group-card-subheader">
              {t('btn.members_amount', {
                amount: currentGroup.attributes?.['user-count'],
              })}
            </Text>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
