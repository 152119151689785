export const GROUPS = {
  GROUPS_DATA: "SET_GROUPS_DATA",
  GROUPS_JOINED: "SET_GROUPS_JOINED",
  GROUPS_HOSTED: "SET_GROUPS_HOSTED",
  GROUPS_INVITATIONS: "SET_GROUPS_INVITATIONS",
  GROUPS_DECISIONS: "SET_GROUPS_DECISIONS",
  CURRENT_GROUP: "SET_CURRENT_GROUP",
  INVITES_OF_GROUP: "SET_INVITES_OF_GROUP",
  USERS_OF_GROUP: "SET_USERS_OF_GROUP",
};
