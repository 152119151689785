import axios from "axios";
import Storage from "common/utils/local-storage";

export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const METHOD = {
  GET: "get",
  HEAD: "HEAD",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
  POST: "POST",
};

const singleton = Symbol();
const singletonEnforcer = Symbol();

const defaultOptions = {
  baseURL: BASE_URL,
  withCredentials: true,
  crossDomain: true,
};

class ApiService {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error("Cannot construct singleton");
    }

    this.session = axios.create(defaultOptions);

    this.session.interceptors.request.use((config) => {
      const token = Storage.get("access_token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }

      // config.headers["X-Platform"] = config.headers["X-Platform"] ? config.headers["X-Platform"] : `Web`;
      config.params = Object.assign({}, config.params || {});

      return config;
    });

    this.session.interceptors.response.use(
      (response) => {
        return response.data;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          Storage.clear();
          window.location.pathname = "login";
        }
        return Promise.reject(error.response);
      },
    );
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new ApiService(singletonEnforcer);
    }

    return this[singleton];
  }

  get = (url, options = {}) =>
    this.session.get(url, { ...defaultOptions, ...options });
  post = (url, data, options = {}) =>
    this.session.post(url, data, { ...defaultOptions, ...options });
  put = (url, data, options = {}) =>
    this.session.put(url, data, { ...defaultOptions, ...options });
  delete = (url, options = {}) =>
    this.session.delete(url, { ...defaultOptions, ...options });
  patch = (url, data, options = {}) =>
    this.session.patch(url, data, { ...defaultOptions, ...options });
}

export default ApiService.instance;
