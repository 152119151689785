import normalize from "json-api-normalizer";

export const mergeRolesByPosition = (response) => {
  const data = response.data || [];
  const included = response.included || [];

  // Ensure both arrays have the same length
  if (data.length !== included.length) {
    console.warn("Data and included arrays have different lengths");
    return included;
  }

  // Merge roles from data items into the corresponding included items by position
  const mergedData = included.map((item, index) => {
    const dataItem = data[index];
    if (dataItem && dataItem.attributes && dataItem.attributes.roles) {
      return {
        ...item,
        roles: dataItem.attributes.roles,
      };
    }
    return item;
  });

  return mergedData;
};

export const normalizeApiResponse = (response) => {
  return normalize(response);
};

export const getErrorMessage = (errorCode) => {
  const errors = {
    auth: {
      invalid_email_or_password: "Invalid email or password",
      login_not_found: "Account not found",
      reset_failed:
        "Unable to reset your password. Please resend the request email.",
      unexpected_error: "Service temporarily unavailable, please try again.",
    },
    authentication: {
      web3: {
        invalid_address: "Public Address is not valid",
        invalid_nonce: "Nonce is not found",
        invalid_signature: "Signature is not valid",
        invite_not_found: "Invite does not exist",
        invite_used: "This invite has already been used. Please sign in.",
        no_metamask:
          "You must install the Metamask plugin to use our web3 features.",
        not_found: "We could not find an account using this Public Address.",
        rejected: "Metamask connection rejected",
        taken: "There is already an account using this Public Address.",
      },
    },
    code: {
      invite_not_found: "Invite does not exist",
      invite_used: "This invite has already been used. Please sign in.",
    },
    invite: {
      invite_not_found: "Invite does not exist",
      invite_used: "This invite has already been used. Please sign in.",
    },
    messages: {
      already_invited: "User is already invited",
      already_in_group: 'User is already in the group',
      invalid_invite_prospect: 'Invalid invite prospect',
      accepted: "must be accepted",
      blank: "can't be blank",
      confirmation: {
        match: "must match password",
        presence: "cannot be blank",
      },
      email: {
        invalid: "must be a valid email",
        presence: "cannot be blank",
      },
      empty: "can't be empty",
      exclusion: "is reserved",
      file: {
        file_type: "Must be an image",
        image_format: "Must be an image",
        size: "File is too big",
      },
      inclusion: "is not included in the list",
      invalid: "is invalid",
      no_html: "must be plain text",
      not_a_number: "is not a number",
      not_an_integer: "must be an integer",
      password: {
        format:
          "must contain at least one uppercase letter, one lowercase letter, a number and symbol",
        length_high: "cannot be more than 128 characters",
        length_low: "must be at least 6 characters",
        presence: "cannot be blank",
      },
      taken: "has already been taken",
      terms: {
        accepted: "must be accepted",
      },
      too_long: {
        one: "is too long (maximum is 1 character)",
        other: "is too long (maximum is %{count} characters)",
      },
      too_short: {
        one: "is too short (minimum is 1 character)",
        other: "is too short (minimum is %{count} characters)",
      },
      username: {
        length: "must be between 3 and 30 characters",
        presence: "cannot be blank",
      },
      wrong_length: {
        one: "is the wrong length (should be 1 character)",
        other: "is the wrong length (should be %{count} characters)",
      },
    },
  };

  const partsOfCode = errorCode.split(".").slice(1);
  let message = errors;

  try {
    for (const part of partsOfCode) {
      message = message[part];
    }

    if (message) return message;
  } catch (error) {
    return "Error";
  }
};

export const timeDifference = (timeString) => {
  const time = new Date(timeString);
  const now = new Date();
  const difference = time - now;

  const absDifference = Math.abs(difference);

  const days = Math.floor(absDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(absDifference / (1000 * 60 * 60)) % 24;
  const minutes = Math.floor(absDifference / (1000 * 60)) % 60;
  const seconds = Math.floor(absDifference / 1000) % 60;

  let result = "";
  if (difference > 0) {
    if (days > 0) {
      result = `${days} Day${days > 1 ? "s" : ""} Left`;
    } else if (hours > 0) {
      result = `${hours} Hour${hours > 1 ? "s" : ""} Left`;
    } else if (minutes > 0) {
      result = `${minutes} Minute${minutes > 1 ? "s" : ""} Left`;
    } else {
      result = `${seconds} Second${seconds > 1 ? "s" : ""} Left`;
    }
  } else {
    if (days > 0) {
      result = `${days} Day${days > 1 ? "s" : ""} Ago`;
    } else if (hours > 0) {
      result = `${hours} Hour${hours > 1 ? "s" : ""} Ago`;
    } else if (minutes > 0) {
      result = `${minutes} Minute${minutes > 1 ? "s" : ""} Ago`;
    } else {
      result = `${seconds} Second${seconds > 1 ? "s" : ""} Ago`;
    }
  }
  return result;
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  function pad(number) {
    return number < 10 ? "0" + number : number;
  }

  const day = pad(date.getUTCDate());
  const month = pad(date.getUTCMonth() + 1);
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = pad(date.getUTCMinutes());

  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedDate = `${day}/${month}/${year} ${hours}:${minutes} ${ampm} UTC`;

  return formattedDate;
};
