// src/translations/i18nextConfig.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import the translation JSON files
import enTranslations from "./translations/en.json";
import frTranslations from "./translations/fr.json";

// Initialize i18next with the local translation resources
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enTranslations,
    },
    fr: {
      translation: frTranslations,
    },
  },
  lng: "en", // Set your default language here
  fallbackLng: "en",
  supportedLngs: ["en", "fr"], // Add other supported languages here
  interpolation: {
    escapeValue: false, // React already protects from XSS
    format: (value, format, lng) => {
      if (format === "uppercase") return value.toUpperCase();
      return value;
    },
  },
  debug: false, // Set to true for debugging
});

// Function to change language
export const changeLanguage = (locale) => {
  i18n.changeLanguage(locale);
};

// Export the i18n instance for use in I18nextProvider
export { i18n };

// Export i18n as default
export default i18n;
