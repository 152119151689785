import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  Text,
  Flex,
  Box,
  ModalBody,
  Spinner,
} from '@chakra-ui/react';
import groupsApi from 'api/groups';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import ModalInviteUser from 'components/ModalInviteUser';
import { getErrorMessage } from 'utils';

export default function ModalEditInvite({ isOpen, onClose, inviteInfo }) {
  const { fetchInvitesOfGroup } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();

  const [linkDecision, setLinkDecision] = useState('none');
  const [emailOrWallet, setEmailOrWallet] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [firstName, setFirstName] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    if (inviteInfo) {
      setLinkDecision(inviteInfo.decision_id);
      setEmailOrWallet(inviteInfo.email);
      setFirstName(inviteInfo.name);
      setRole(inviteInfo.role);
    }
  }, [inviteInfo]);

  const handleClose = () => {
    onClose();
    setEmailOrWallet('');
    setLinkDecision('');
    setSendEmail(false);
    setIsLoading(false);
    setFirstName('');
    setRole('');
  };

  const editUserInvite = async () => {
    const data =
      linkDecision && linkDecision !== 'none'
        ? {
            data: {
              type: 'group-invite',
              attributes: {
                email: emailOrWallet,
                name: firstName,
                roles: [role],
                send_email: sendEmail,
              },
              relationships: {
                decision: {
                  data: {
                    id: linkDecision,
                    type: 'decision',
                  },
                },
              },
            },
          }
        : {
            data: {
              type: 'group-invite',
              attributes: {
                email: emailOrWallet,
                name: firstName,
                roles: [role],
                send_email: sendEmail,
              },
            },
          };

    try {
      setIsLoading(true);
      await groupsApi.editGroupInvite(inviteInfo.id, data);
      await fetchInvitesOfGroup(inviteInfo.group_id);
      handleClose();
    } catch (e) {
      setIsLoading(false);
      console.error('Error inviting user to group:', e);
      const error = e?.data?.errors[0]?.[0]?.detail;
      if (error) {
        setErrorMessage(getErrorMessage(error));
      }
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: '90%', sm: '80%', md: '70%', lg: '60%' }}
        padding={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          borderBottom="1px"
          borderColor="gray.300"
          padding={4}
        >
          <Text fontSize="lg" fontWeight="bold" mb={0}>
            {t('group.edit_invite')}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {t('group.edit.description')}
          </Text>
        </Box>
        <ModalBody paddingY={4}>
          <ModalInviteUser
            setEmailOrWallet={setEmailOrWallet}
            setLinkDecision={setLinkDecision}
            emailOrWallet={emailOrWallet}
            linkDecision={linkDecision}
            setSendEmail={setSendEmail}
            setFirstName={setFirstName}
            errorMessage={errorMessage}
            firstName={firstName}
            setRole={setRole}
            role={role}
          />
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Flex w="100%" justifyContent="space-between" gap={4}>
            <Button
              className="first-button-modal-invite"
              onClick={() => handleClose()}
            >
              {t('btn.go_back')}
            </Button>
            <Button
              className="second-button-modal-invite"
              onClick={() => editUserInvite()}
            >
              {isLoading ? <Spinner /> : t('btn.confirm')}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
