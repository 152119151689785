import React, { useState } from "react";
import { Box, Text, Link, Flex, Button, Circle } from "@chakra-ui/react";
import AcceptInvitationModal from "components/AcceptInvitationModal";
import useMarkdownTranslations from "../../../translations/i18nextConfig/useMarkdownTranslations";

export default function CardInvited({ group }) {
  const { t } = useMarkdownTranslations();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box p={4} borderWidth="1px" borderRadius="md">
      <Flex
        textAlign="left"
        mb={8}
        pb={4}
        justifyContent="flex-start"
        alignItems="center"
        width="100%"
      >
        <Flex
          textAlign="left"
          mb={2}
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
        >
          <Circle
            size="60px"
            className="group-identification-circle"
            color="white"
            mr={4}
          >
            {group?.attributes?.title?.charAt(0)?.toUpperCase()}
          </Circle>
          <Box>
            <Text className="group-card-header">
              {group?.attributes?.title}
            </Text>
            <Link className="group-card-link">
              {group?.attributes?.url || "https://www.testing.com"}
            </Link>
            <Text className="group-card-subheader">
              {t("btn.members_amount", {
                amount: group?.attributes?.["user-count"],
              })}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Flex justify="space-between" align="center" mb={4} mt={4} gap={2}>
        <Button flex="1" className="main-button" onClick={handleOpenModal}>
          {t("btn.accept_invitation")}
        </Button>
        <Button flex="1" className="secondary-button" onClick={handleOpenModal}>
          {t("btn.decline_invitation")}
        </Button>
      </Flex>
      <Flex justify="space-between" align="center">
        <Button
          width="100%"
          className="main-button"
          textAlign="left"
          justifyContent="flex-start"
          onClick={handleOpenModal}
        >
          {t("btn.view_info")}
        </Button>
      </Flex>
      <AcceptInvitationModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        group={group}
      />
    </Box>
  );
}
