import { useEffect, useState } from "react";
import {
  Flex,
  Input,
  Button,
  InputGroup,
  Stack,
  Box,
  Image,
  FormControl,
  Center,
  Text,
  Grid,
  InputLeftElement,
} from "@chakra-ui/react";
import userApi from "api/auth";
import { useCurrentUserContext } from "features/user/contexts/user-provider";
import { useNavigate, useLocation } from "react-router-dom";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import { getErrorMessage } from "utils";
import "./styles.css";
import politySideImage from "../../assets/images/polity_side.png";
import passwordIcon from "../../assets/images/password_icon.png";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const navigate = useNavigate();
  const { login } = useCurrentUserContext();
  const { t } = useMarkdownTranslations();
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const location = useLocation();

  // Extract the token from the query parameters
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  const resetPassword = () => {
    const data = { password, password_confirmation: repeatedPassword, token };

    userApi
      .resetPassword({ user: data })
      .then(() => {
        login();
        navigate("/", { replace: true });
      })
      .catch((e) => {
        setError(true);
        e?.data?.errors?.forEach((currentError) => {
          setErrorMessage(getErrorMessage(currentError.detail));
        });
      });
  };

  useEffect(() => {
    setError(false);
  }, [repeatedPassword, password]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Flex className="main-flex-reset-password" height="100vh">
      <Grid className="grid-reset-password">
        {windowWidth > 768 && (
          <Image
            src={politySideImage}
            className="side-image-reset-password"
            minHeight="100vh"
            maxHeight="100vh"
          />
        )}
        <Stack
          className="main-stack-reset-password"
          minHeight="100vh"
          maxHeight="100vh"
          justifyContent="center"
        >
          <Box className="register-text-reset-password">
            {t("authentication.password.new")}
          </Box>
          <Box className="welcome-text-reset-password">
            {t("company.welcome")}
          </Box>
          <Box className="main-box-reset-password">
            <Stack
              className="secondary-stack-reset-password"
              role="form"
              aria-label={t("accessibility.authentication.login_form")}
            >
              <FormControl>
                <Center>
                  <Flex
                    className="flex-column-reset-password"
                    direction="column"
                    w="100%"
                  >
                    <InputGroup>
                      <InputLeftElement
                        className="input-left-element-reset-password"
                        children={
                          <Image
                            src={passwordIcon}
                            alt={t("authentication.password")}
                          />
                        }
                      />
                      <Input
                        placeholder={t("authentication.password.new")}
                        value={password}
                        aria-labelledby="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        className={"input-reset-password"}
                      />
                    </InputGroup>
                    {error && (
                      <Text className="error-text-reset-password">
                        {errorMessage}
                      </Text>
                    )}
                  </Flex>
                </Center>
              </FormControl>
              <FormControl>
                <Center>
                  <Flex
                    className="flex-column-reset-password"
                    direction="column"
                    w="100%"
                  >
                    <InputGroup>
                      <InputLeftElement
                        className="input-left-element-reset-password"
                        children={
                          <Image
                            src={passwordIcon}
                            alt={t("authentication.password")}
                          />
                        }
                      />
                      <Input
                        placeholder={t("authentication.password.repeat")}
                        value={repeatedPassword}
                        aria-labelledby="repeat-password"
                        onChange={(e) => setRepeatedPassword(e.target.value)}
                        type="password"
                        className={"input-reset-password"}
                      />
                    </InputGroup>
                    {error && (
                      <Text className="error-text-reset-password">
                        {errorMessage}
                      </Text>
                    )}
                  </Flex>
                </Center>
              </FormControl>
              <Box className="button-box-reset-password" mt={3}>
                <Center>
                  <Button
                    className="login-button-reset-password"
                    onClick={() => resetPassword()}
                  >
                    {t("btn.save")}
                  </Button>
                </Center>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Grid>
    </Flex>
  );
};

export default ResetPassword;
