import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Link,
  Flex,
  Image,
  VStack,
  Tooltip,
  Text,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCurrentGroupsContext } from '../../features/groups/contexts/groups-provider';
import { useCurrentUserContext } from '../../features/user/contexts/user-provider';
import documentIcon from '../../assets/images/document.png';
import documentDarkIcon from '../../assets/images/document_dark.png';
import homeIcon from '../../assets/images/home.png';
import homeDarkIcon from '../../assets/images/home_dark.png';
import { useTranslation } from 'react-i18next';

export default function Sidebar({ isOpen, lightMode }) {
  const { groupsData } = useCurrentGroupsContext();
  const { t } = useTranslation();
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const sidebarRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { user } = useCurrentUserContext();

  let isHost = true;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (sidebarRef.current) {
      setSidebarWidth(sidebarRef.current.offsetWidth);
    }
  }, [sidebarRef, isOpen]);

  const linkItems = [
    {
      name: t('sidebar.link.translation'),
      icon: documentIcon,
      iconDarkMode: documentDarkIcon,
      link: '/translation',
      alt: t('sidebar.link.translation'),
      display: user?.['is-staff'],
    },
  ];

  const topGroups = groupsData.slice(0, 5);
  const showSeeAll = groupsData.length > 5;

  return (
    <Flex direction="row">
      <Box
        position="fixed"
        height="100vh"
        paddingTop={9}
        backgroundColor="var(--CLEAR-BACKGROUND)"
        zIndex={1000}
        ref={sidebarRef}
        overflowY="auto"
        pl={4}
        display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
      >
        <VStack spacing={4} align="flex-start">
          {topGroups.length > 0 &&
            (isOpen ? (
              <Box>
                <Flex align="center" p={0} mb={0}>
                  <Link
                    as={RouterLink}
                    to={`/g/${topGroups[0]?.attributes?.slug}`}
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Flex direction="row">
                      <Box
                        margin={0}
                        w="25px"
                        h="25px"
                        bg="blue.500"
                        borderRadius="full"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        fontWeight="bold"
                        fontSize="14px"
                        lineHeight="1"
                        mr={2}
                      >
                        {topGroups[0]?.attributes?.title?.charAt(0) || 'G'}
                      </Box>
                      <Box className="text-up-select-sidebar">
                        {topGroups[0]?.attributes?.title}
                      </Box>
                    </Flex>
                  </Link>
                  {topGroups.length > 1 && (
                    <FontAwesomeIcon
                      icon={openMenu ? faAngleUp : faAngleDown}
                      style={{ marginLeft: '6px', marginTop: '-2px' }}
                      cursor="pointer"
                      onClick={() => setOpenMenu(!openMenu)}
                    />
                  )}
                  <Link as={RouterLink} to={`/user/groups`}>
                    <Image
                      _hover={{ cursor: 'pointer' }}
                      ml={5}
                      src={lightMode ? homeIcon : homeDarkIcon}
                      w={5}
                      mr={3}
                    />
                  </Link>
                </Flex>
              </Box>
            ) : (
              <Box>
                <Flex align="center" p={0} mb={0}>
                  <Tooltip
                    label={t('sidebar.link.home_group')}
                    aria-label={t('accessibility.tooltip.home_group')}
                    placement="right"
                  >
                    <Link as={RouterLink} to={`/user/groups`}>
                      <Image
                        _hover={{ cursor: 'pointer' }}
                        src={lightMode ? homeIcon : homeDarkIcon}
                        w={5}
                        ml={1}
                      />
                    </Link>
                  </Tooltip>
                </Flex>
              </Box>
            ))}

          {topGroups.length > 1 && isOpen && openMenu && (
            <Flex
              align="flex-start"
              direction="column"
              margin={0}
              pt={0}
              pb={4}
              gap={2}
              borderBottom="2px #E8E8E8 solid"
              mr={12}
            >
              {topGroups.slice(1).map((group, i) => (
                <Flex key={i} mb={1}>
                  <Link
                    as={RouterLink}
                    to={`/g/${group?.attributes?.slug}`}
                    _hover={{ textDecoration: 'none' }}
                  >
                    <Flex direction="row" m={0} p={0}>
                      <Box
                        w="26px"
                        h="26px"
                        bg="red.500"
                        borderRadius="full"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        color="white"
                        fontWeight="bold"
                        fontSize="14px"
                        lineHeight="1"
                        mr={1}
                      >
                        {group?.attributes?.title?.charAt(0) || 'G'}
                      </Box>
                      <Box className="text-up-sidebar" pt="2px">
                        {group?.attributes?.title}
                      </Box>
                    </Flex>
                  </Link>
                </Flex>
              ))}
              {showSeeAll && (
                <Link as={RouterLink} to="/user/groups" className="link">
                  <Flex align="center">
                    <Text color="blue.500" fontWeight="bold">
                      {t('sidebar.link.seeAll')}
                    </Text>
                  </Flex>
                </Link>
              )}
            </Flex>
          )}
          {linkItems.map((link, i) =>
            isOpen ? (
              <span key={i}>
                {link.display && (
                  <Link as={RouterLink} to={link.link} className="link">
                    <Flex align="center">
                      <Image
                        src={lightMode ? link.icon : link.iconDarkMode}
                        className="image"
                        alt={link.alt}
                        boxSize="24px"
                        mr={2}
                      />
                      <Box className="text-up-sidebar">{link.name}</Box>
                    </Flex>
                  </Link>
                )}
              </span>
            ) : (
              <span key={i}>
                {link.display && (
                  <Tooltip
                    label={link.name}
                    aria-label={link.alt}
                    placement="right"
                  >
                    <Link as={RouterLink} to={link.link} className="link">
                      <Flex align="center">
                        <Image
                          src={lightMode ? link.icon : link.iconDarkMode}
                          className="image"
                          alt={link.alt}
                          boxSize="24px"
                          mr={2}
                        />
                      </Flex>
                    </Link>
                  </Tooltip>
                )}
              </span>
            ),
          )}
        </VStack>
      </Box>
      <div style={{ width: windowWidth >= 768 ? sidebarWidth : 0 }}></div>
    </Flex>
  );
}
