import Client from "api/client";

const api = () => {
  return {
    getCurrentUser: () => {
      return Client.get("member/current_user");
    },
    updateCurrentUser: (data) => {
      return Client.post("member/current_user", data);
    },
    editProfile: (data) => {
      return Client.post("member/update_profile.json", data);
    },
    deleteProfile: () => {
      return Client.get("member/delete_request.json");
    },
  };
};

export default api();
