import React, { useEffect, useRef, useState } from 'react';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import politySideImage from 'assets/images/polity_side.png';
import ResourceNotFound from './Sections/ResourceNotFound';
import { useLocation, useParams } from 'react-router-dom';
import JoinWithAccount from './Sections/JoinWithAccount';
import down_arrow from 'assets/images/down_arrow.svg';
import arrow_up from 'assets/images/arrow_up.svg';
import { getErrorMessage } from 'utils';
import groupsApi from 'api/groups';
import './styles.css';
import { Flex, Button, Stack, Box, Image, Grid, Text } from '@chakra-ui/react';
import { Separator } from 'components/Separator';
import GroupHeader from 'components/GroupHeader';
import JoinWithoutAccount from './Sections/JoinWithoutAccount';
import LoadingScreen from 'components/LoadingScreen';
import DecisionRedirect from './Sections/DecisionRedirect';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';

const JoinUs = () => {
  const { fetchGroupBySlug, currentGroup } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();
  const location = useLocation();
  const { slug } = useParams();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [displayGroupInfo, setDisplayGroupInfo] = useState(false);
  const [isResourceFound, setIsResourceFound] = useState(true);
  const queryParams = new URLSearchParams(location.search);
  const [errorMessage, setErrorMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [userExist, setUserExist] = useState(false);
  const [redirect, setRedirect] = useState('');
  const invite_token = queryParams.get('invite_token');
  const { login } = useCurrentUserContext();
  const textRef = useRef(null);

  const verifyUserExists = async () => {
    try {
      setIsloading(true);
      const { data } = await groupsApi.verifyInviteUser(slug, invite_token);
      if (data.relationships.user.data === null) setUserExist(false);
      else {
        login();
        setUserExist(true);
      }
      setIsResourceFound(true);
      setIsloading(false);
    } catch (e) {
      setIsloading(false);
      const error = e?.data?.errors?.[0]?.detail;
      if (error) setErrorMessage(getErrorMessage(error));
      else {
        setIsResourceFound(false);
      }
    }
  };

  useEffect(() => {
    if (currentGroup) {
      verifyUserExists();
    }
  }, [currentGroup]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchGroupBySlug(slug);
      } catch (e) {
        setIsloading(false);
        const error = e?.data?.errors?.[0]?.detail;
        if (error) setErrorMessage(getErrorMessage(error));
        else {
          setIsResourceFound(false);
        }
      }
    };
    if (slug) fetchData();
  }, [slug]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleInfo = () => {
    setDisplayGroupInfo(!displayGroupInfo);
    setIsExpanded(false);
  };

  if (isLoading) return <LoadingScreen loading={isLoading} />;
  return (
    <Flex className="join-us-container" height="100vh">
      <Grid className="join-us-grid">
        {windowWidth > 768 && (
          <Image
            src={politySideImage}
            className="join-us-image"
            minHeight="100vh"
            maxHeight="100vh"
          />
        )}
        <Stack
          className="join-us-form"
          minHeight="100vh"
          maxHeight="100vh"
          spacing={0}
          justifyContent="center"
        >
          <h1 className="banner-bold">
            {(!userExist || !isResourceFound) &&
              `${t('tooltip.invite')} ${t('pathname.not_found')}`}
            {userExist &&
              isResourceFound &&
              !!redirect &&
              t('group.join_us.thanks')}
            {userExist && isResourceFound && !redirect && t('group.join_us')}
          </h1>
          {!redirect && isResourceFound && (
            <p className="subtitle-medium">
              {t('group.join_us.description.first_half')}&nbsp;
              <b>{currentGroup?.attributes?.title}</b>&nbsp;
              {t('group.join_us.description.second_half')}
            </p>
          )}
          {!!redirect && isResourceFound && (
            <p className="subtitle-medium">
              {t('decision.sending')}&nbsp;
              <b>{'Decision name'}</b>&nbsp;
              {t('decision.start_voting')}&nbsp;
              <u>click here</u>
            </p>
          )}
          {!isResourceFound && (
            <p className="subtitle-medium">{t('group_invite.not_found')}</p>
          )}
          {!redirect && (
            <>
              <Separator />
              <p className="input-form-label">
                {t('group.join_us.polity.first_half')}&nbsp;
                {currentGroup?.attributes?.title}&nbsp;
                {t('group.join_us.polity.second_half')}
              </p>
            </>
          )}
          <Separator />
          {!isResourceFound && <ResourceNotFound />}
          {userExist && isResourceFound && !redirect && (
            <JoinWithAccount setRedirect={setRedirect} />
          )}
          {userExist && isResourceFound && !!redirect && (
            <DecisionRedirect redirect={redirect} />
          )}
          {!userExist && isResourceFound && !redirect && (
            <JoinWithoutAccount setRedirect={setRedirect} />
          )}
          {isResourceFound && !redirect && (
            <>
              <Separator margin />
              <Flex className="group-header-content">
                <Flex direction="column">
                  <GroupHeader />
                </Flex>
                <Flex direction="column">
                  <Button
                    flex="1"
                    _hover={{
                      textDecoration: 'none',
                      background: 'transparent',
                    }}
                    textDecoration={'none'}
                    onClick={toggleInfo}
                    bg="transparent"
                    backgroundColor="transparent"
                  >
                    {displayGroupInfo ? (
                      <Image
                        color="var(--MAIN-GREY)"
                        src={down_arrow}
                        alt={t('btn.group.view_link')}
                      />
                    ) : (
                      <Image
                        color="var(--MAIN-GREY)"
                        src={arrow_up}
                        alt={t('btn.group.view_link')}
                      />
                    )}
                  </Button>
                </Flex>
              </Flex>
              {displayGroupInfo && (
                <>
                  <Text className="group-card-header">Info</Text>
                  <Box>
                    <Text
                      className="modal-header-secondary"
                      noOfLines={isExpanded ? null : 3}
                      textOverflow="ellipsis"
                      overflow="hidden"
                      ref={textRef}
                    >
                      {currentGroup.attributes.info}
                    </Text>
                    <Text
                      as="span"
                      cursor="pointer"
                      className="modal-header-secondary-bold"
                      onClick={toggleExpand}
                    >
                      {isExpanded ? 'See less' : 'See more'}
                    </Text>
                  </Box>
                </>
              )}
            </>
          )}
        </Stack>
      </Grid>
    </Flex>
  );
};

export default JoinUs;
