import { Button, Box, Center, Link } from '@chakra-ui/react';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';

const DecisionRedirect = ({ redirect }) => {
  const { t } = useMarkdownTranslations();
  const { login } = useCurrentUserContext();

  if (!redirect) return null;

  return (
    <Box>
      <Center>
        <Link href={redirect} target="_blank" width="100%">
          <Button className="accept-btn" onClick={() => login()}>
            {t('btn.go_to_decision')}
          </Button>
        </Link>
      </Center>
    </Box>
  );
};

export default DecisionRedirect;
