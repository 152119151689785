import React from 'react';
import { Checkbox } from '@chakra-ui/react';
import useMarkdownTranslations from '../translations/i18nextConfig/useMarkdownTranslations';

export default function SentEmailCheckBox({ setSendEmail }) {
  const { t } = useMarkdownTranslations();

  return (
    <Checkbox
      size="lg"
      iconSize="1.5em"
      mt={4}
      onChange={(e) => setSendEmail(e.target.checked)}
      sx={{
        'span.chakra-checkbox__control': {
          width: '24px',
          height: '24px',
          backgroundColor: 'var(--INPUT-BACKGROUND)',
          boxShadow: 'none',
          _checked: {
            bg: 'var(--INPUT-BACKGROUND)',
            color: 'var(--MAIN-GREY)',
          },
        },
        'span.chakra-checkbox__control[data-checked]': {
          backgroundColor: 'var(--INPUT-BACKGROUND)',
          color: 'var(--MAIN-GREY)',
        },
        'span.chakra-checkbox__control:focus': {
          boxShadow: 'none',
        },
      }}
    >
      <span className="checkbox-text-modal-invite-participants">
        {t('checkbox.send_email')}
      </span>
    </Checkbox>
  );
}
