import React from "react";
import {
  Modal,
  ModalOverlay,
  Link,
  ModalBody,
  Button,
  ModalContent,
  Text,
  Image,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import "./styles.css";
import polityImage from "../../assets/images/polity.png";

export default function CreateDecisionModal({
  editDecisionLink,
  openModal,
  setOpenModal,
}) {
  const { t } = useMarkdownTranslations();

  return (
    <Modal isOpen={openModal} onClose={() => setOpenModal(false)}>
      <ModalOverlay />
      <ModalContent className="modal-content-create-decision-modal">
        <ModalBody className="modal-body-text-create-decision-modal">
          <Image
            src={polityImage}
            className="ethelo-images-create-decision-modal"
            alt={t("company.name")}
          />
          <Text
            className="text-decision-ready-to-edit-create-decision-modal"
            role="heading"
            aria-level="1"
          >
            {t("decision.ready.is_ready_for_editing")}
          </Text>
          <Text
            className="text-decision-ready-create-decision-modal"
            role="heading"
            aria-level="2"
          >
            {t("decision.ready.is_ready")}
          </Text>
          <Link
            className="link-to-edit-decision-create-decision-modal"
            href={editDecisionLink}
            target="_blank"
          >
            <Button className="button-create-decision-modal">
              {t("decision.edit")}
            </Button>
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
