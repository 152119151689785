export function Separator({ vertical, className, margin }) {
  const borderOrientation = vertical
    ? 'separator-vertical'
    : 'separator-horizontal';
  const margins = margin ? 'margin-16' : 'margin-24';

  return (
    <div
      className={`separator ${borderOrientation} ${margins}  ${className ?? ''}`}
    />
  );
}
