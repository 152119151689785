import React, { useState, useEffect, useRef } from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { useCurrentUserContext } from 'features/user/contexts/user-provider';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Text,
  Box,
  Image,
  Link,
  Avatar,
  IconButton,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import useMarkdownTranslations from '../../translations/i18nextConfig/useMarkdownTranslations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import './styles.css';
import {
  changeLanguage,
  i18n,
} from '../../translations/i18nextConfig/i18nextConfig';
import polityImage from '../../assets/images/polity.png';
import polityDarkModeImage from '../../assets/images/polity_dark_mode.png';
import moneyImage from '../../assets/images/money.png';
import moneyDarkModeImage from '../../assets/images/money_dark_mode.png';
import darkModeImage from '../../assets/images/darkmode.png';
import darkModeDarkImage from '../../assets/images/darkmode_dark.png';
import logoutIconImage from '../../assets/images/logout_icon.png';
import logoutIconDarkImage from '../../assets/images/logout_icon_dark.png';

export default function HeaderComponent({
  onOpen,
  onClose,
  isOpen,
  changeBetweenLightAndDarkMode,
  lightMode,
}) {
  const { authorized, logout, user, isLoaded } = useCurrentUserContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useMarkdownTranslations();
  const [navbarHeight, setNavbarHeight] = useState(0);
  const navbarRef = useRef(null);
  const menuRef = useRef(null);

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.offsetHeight);
    }
  }, [navbarRef, isLoaded, authorized]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLanguageChange = async (locale) => {
    try {
      await changeLanguage(locale);
      setIsMenuOpen(false);
    } catch (error) {
      //todo: handle error
    }
  };

  return (
    <>
      <Flex
        className={`flex ${
          authorized ? 'flex-authorized' : 'flex-unauthorized'
        }`}
        role="navigation"
        aria-label={t('accessibility.navigation.header')}
        ref={navbarRef}
        zIndex={10}
      >
        <Flex className="flexWithLogos">
          {isLoaded && authorized && (
            <IconButton
              className="iconButton"
              style={{ backgroundColor: 'var(--CLEAR-BACKGROUND)' }}
              onClick={isOpen ? onClose : onOpen}
              size={{ base: 'sm', md: 'md', lg: 'lg' }}
              icon={
                <HamburgerIcon
                  className="hamburguerIcon"
                  color="var(--MAIN-GREY-TEXT)"
                />
              }
              alt={t('accessibility.navigation.hamburger')}
              aria-label={t('accessibility.navigation.hamburger')}
            />
          )}
          <Link
            as={LinkRouter}
            to="/"
            className="linkOfLogo"
            _hover={{ textDecoration: 'none' }}
          >
            <Image
              className="logoImage"
              src={lightMode ? polityImage : polityDarkModeImage}
              alt={t('navigation.logo')}
            />
          </Link>
          <Link
            href="#main"
            tabIndex="1"
            aria-label={t('accessibility.navigation.main_link')}
          >
            <Text></Text>
          </Link>
        </Flex>
        <Flex className="flexWithLinks">
          {isLoaded && !authorized && (
            <Link as={LinkRouter} to="/login" className="link">
              {t('navigation.login')}
            </Link>
          )}
          {isLoaded && !authorized && (
            <Link as={LinkRouter} to="/signup" className="link">
              {t('navigation.sign_up')}
            </Link>
          )}
          {isLoaded && authorized && (
            <Flex className="flexRow">
              <Flex className="flexWithNameAndAvatar">
                {user?.avatar && (
                  <Avatar
                    className="avatar"
                    src={user?.avatar}
                    alt={t('navigation.participant')}
                  />
                )}
                {user && user?.name && (
                  <Flex className="flexWithUserName" direction="column">
                    <Text margin={0} className="name-user-header">
                      {user?.name}
                    </Text>
                    <Flex alignItems="center">
                      <Text
                        margin={0}
                        className="name-text-header"
                        as={LinkRouter}
                        to="/account"
                      >
                        {t('navigation.my_account')}
                      </Text>
                      <FontAwesomeIcon
                        icon={isMenuOpen ? faAngleUp : faAngleDown}
                        style={{ marginLeft: '24px', marginTop: '-16px' }}
                        cursor="pointer"
                        onClick={toggleMenu}
                      />
                    </Flex>
                  </Flex>
                )}
              </Flex>

              {isMenuOpen && (
                <Box ref={menuRef} mt={'5em'}>
                  <Menu isOpen={true}>
                    <MenuButton as={Box} />
                    <MenuList className="menu-list-header" gap={4}>
                      <MenuItem
                        as={LinkRouter}
                        to="/wallet"
                        className="menu-item-header"
                      >
                        <Image
                          src={lightMode ? moneyImage : moneyDarkModeImage}
                          marginRight={3}
                        />
                        {t('navigation.my_wallet')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => changeBetweenLightAndDarkMode()}
                        className="menu-item-header"
                      >
                        <Flex alignItems="center" direction="row">
                          <Image
                            src={lightMode ? darkModeImage : darkModeDarkImage}
                            marginRight={3}
                            height="24px"
                          />
                          <Flex
                            direction="column"
                            justify="center"
                            height="24px"
                            padding={0}
                            gap={0}
                          >
                            <Text m={0}>{t('navigation.dark_mode')}</Text>
                            <Text fontSize="xs" color="gray.500" m={0}>
                              {lightMode
                                ? t('navigation.turn.off')
                                : t('navigation.turn.on')}
                            </Text>
                          </Flex>
                        </Flex>
                      </MenuItem>
                      <MenuItem
                        onClick={() => logout()}
                        className="menu-item-header"
                      >
                        <Image
                          src={
                            lightMode ? logoutIconImage : logoutIconDarkImage
                          }
                          marginRight={3}
                        />
                        {t('navigation.logout')}
                      </MenuItem>
                      {i18n.language.startsWith('en') ? (
                        <MenuItem
                          onClick={() => handleLanguageChange('fr')}
                          className="menu-item-header"
                        >
                          {t('language.french')}
                        </MenuItem>
                      ) : (
                        <MenuItem
                          onClick={() => handleLanguageChange('en')}
                          className="menu-item-header"
                        >
                          {t('language.english')}
                        </MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Box>
              )}
            </Flex>
          )}
        </Flex>
      </Flex>
      <div style={{ height: navbarHeight }}></div>
    </>
  );
}
