import { useState } from 'react';
import { Button, Box, Center, Spinner } from '@chakra-ui/react';
import useMarkdownTranslations from '../../../translations/i18nextConfig/useMarkdownTranslations';
import { useCurrentGroupsContext } from 'features/groups/contexts/groups-provider';
import groupsApi from 'api/groups';
import { getErrorMessage } from 'utils';
import { useNavigate } from 'react-router-dom';

const JoinWithAccount = ({ setRedirect }) => {
  const { currentGroup, fetchGroupsAndInvitations } = useCurrentGroupsContext();
  const { t } = useMarkdownTranslations();

  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const acceptInvitation = async () => {
    setIsLoading(true);
    try {
      const { data } = await groupsApi.acceptGroupInvite(currentGroup.id);
      await fetchGroupsAndInvitations();
      redirectUser(data);
      setIsLoading(false);
    } catch (e) {
      const error = e?.data?.errors?.[0]?.detail;
      if (error) setErrorMessage(getErrorMessage(error));
      setIsLoading(false);
    }
  };

  const redirectUser = (data) => {
    if (data.links['join-redirect']) setRedirect(data.links['join-redirect']);
    else {
      navigate(`/g/${currentGroup.attributes.slug}`, { replace: true });
    }
  };

  if (!currentGroup?.attributes) return null;

  return (
    <Box>
      <Center>
        <Button className="accept-btn" onClick={() => acceptInvitation()}>
          {isLoading ? <Spinner /> : t('btn.accept_invitation')}
        </Button>
      </Center>
    </Box>
  );
};

export default JoinWithAccount;
