import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  Box,
  Textarea,
  Avatar,
  Link,
} from "@chakra-ui/react";
import { Select as SelectWithSearch } from "chakra-react-select";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import groupsApi from "api/groups";

const getTimezoneObject = (timezone) => {
  const currentTime = moment.tz(timezone);
  const offsetHours = currentTime.utcOffset() / 60;
  return {
    value: timezone,
    label: `(GMT${
      offsetHours >= 0 ? `+${offsetHours}` : offsetHours
    }) ${timezone}`,
  };
};

const getTimezonesInFormat = () => {
  const timezones = moment.tz.names();
  return [
    { value: "", label: "" }, // Add empty option
    ...timezones
      .map((timezone) => {
        const currentTime = moment.tz(timezone);
        const offsetHours = currentTime.utcOffset() / 60;
        return {
          value: timezone,
          label: `(GMT${
            offsetHours >= 0 ? `+${offsetHours}` : offsetHours
          }) ${timezone}`,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];
};

export default function ModalEditDesc({
  isOpen,
  onClose,
  onDeleteGroupOpen,
  currentGroup,
}) {
  const { t } = useMarkdownTranslations();
  const closeEditOpenAndOpenDelete = () => {
    onClose();
    onDeleteGroupOpen();
  };

  // Add useState hooks
  const [groupName, setGroupName] = useState("");
  const [identifier, setIdentifier] = useState("");
  const [moreInfoUrl, setMoreInfoUrl] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const [language, setLanguage] = useState("");
  const [timeZone, setTimeZone] = useState(getTimezoneObject("UTC"));
  const [timezonesOptions, setTimezonesOptions] = useState([]);

  const editGroup = async () => {
    const groupData = { group: {} };

    if (groupName) groupData.group.title = groupName;
    if (identifier) groupData.group.slug = identifier;
    if (moreInfoUrl) groupData.group.url = moreInfoUrl;
    if (groupDescription) groupData.group.info = groupDescription;
    if (language) groupData.group.language = language;
    if (timeZone && timeZone.value) groupData.group.time_zone = timeZone.value;

    try {
      await groupsApi.editGroup(currentGroup.id, groupData);
      onClose();
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setTimezonesOptions(getTimezonesInFormat());
    // If editing an existing group, set the current timezone
    if (currentGroup && currentGroup.timeZone) {
      setTimeZone(getTimezoneObject(currentGroup.timeZone));
    }
  }, [isOpen, currentGroup]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "90%", sm: "80%", md: "70%", lg: "60%" }}
        padding={4}
      >
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          borderBottom="1px"
          borderColor="gray.300"
          padding={4}
        >
          <Text fontSize="lg" fontWeight="bold" mb={0}>
            {t("group.edit")}
          </Text>
          <Text fontSize="sm" color="gray.600">
            {t("group.edit.description")}
          </Text>
        </Box>
        <ModalBody paddingY={4}>
          <Box p={4}>
            <Flex alignItems="center" gap={4}>
              <Box position="relative">
                <Avatar
                  bg="blue.600"
                  color="white"
                  name="Power Group"
                  size="md"
                  fontSize="md"
                />
                <Box
                  position="absolute"
                  bottom={-2}
                  right={-2}
                  bg="white"
                  borderRadius="full"
                  py={1}
                  px={2}
                  boxShadow="sm"
                >
                  <FontAwesomeIcon icon={faDownload} size="sm" />
                </Box>
              </Box>
              <Box>
                <Text fontSize="md" fontWeight="medium" mb={1}>
                  {t("group.power_name")}
                </Text>
                <Link
                  fontSize="sm"
                  color="gray.600"
                  textDecoration="underline"
                  _hover={{ color: "blue.500" }}
                >
                  {t("group.change_logo")}
                </Link>
              </Box>
            </Flex>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={4}>
            <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
              <FormLabel htmlFor="group-name" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t("group.name")}</span>
                  <span>*</span>
                </Flex>
              </FormLabel>
              <Input
                id="group-name"
                className="input-modal-invite-participants"
                value={groupName}
                onChange={(e) => setGroupName(e.target.value)}
              />
            </FormControl>

            <FormControl gridColumn={{ base: "span 1", md: "span 2" }}>
              <FormLabel htmlFor="identifier" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t("group.identifier_info")}</span>
                  <span>*</span>
                </Flex>
              </FormLabel>
              <Input
                id="identifier"
                className="input-modal-invite-participants"
                value={identifier}
                onChange={(e) => setIdentifier(e.target.value)}
              />
            </FormControl>

            <FormControl
              gridColumn={{ base: "span 1", md: "span 3" }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel
                  htmlFor="more-info-url"
                  className="input-label-texts"
                >
                  {t("group.url.more_info")}
                </FormLabel>
              </Box>
              <Input
                id="more-info-url"
                className="input-modal-invite-participants"
                value={moreInfoUrl}
                onChange={(e) => setMoreInfoUrl(e.target.value)}
              />
            </FormControl>
            <FormControl
              gridColumn={{ base: "span 1", md: "span 3" }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel
                  htmlFor="group-description"
                  className="input-label-texts"
                >
                  {t("group.description")}
                </FormLabel>
              </Box>
              <Textarea
                id="group-description"
                className="input-modal-invite-participants"
                w="100%"
                value={groupDescription}
                onChange={(e) => setGroupDescription(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>
          <Text fontSize="md" fontWeight="bold" mt={4}>
            {t("group.decisions.default")}
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mt={4}>
            <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
              <FormLabel htmlFor="language" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t("language")}</span>
                </Flex>
              </FormLabel>
              <SelectWithSearch
                id="language"
                className="select-participate-page"
                options={[
                  { value: "en", label: t("language.english") },
                  { value: "fr", label: t("language.french") },
                ]}
                value={
                  language
                    ? { value: language, label: language.toUpperCase() }
                    : null
                }
                onChange={(selected) =>
                  setLanguage(selected ? selected.value : "")
                }
                placeholder={t("language.select")}
              />
            </FormControl>
            <FormControl gridColumn={{ base: "span 1", md: "span 1" }}>
              <FormLabel htmlFor="time-zone" className="input-label-text">
                <Flex justifyContent="space-between" alignItems="center">
                  <span>{t("select.header.timezone")}</span>
                </Flex>
              </FormLabel>
              <SelectWithSearch
                id="time-zone"
                className="select-participate-page"
                options={timezonesOptions}
                value={timeZone}
                onChange={setTimeZone}
                placeholder={t("select.placeholder.timezone")}
              />
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter margin={0} pt={0} marginBottom={2}>
          <Box w="100%" display="flex" flexDirection="column" gap={6}>
            <Flex w="100%" justifyContent="space-between" gap={4}>
              <Button
                className="first-button-modal-invite"
                onClick={() => onClose()}
              >
                {t("btn.go_back")}
              </Button>
              <Button
                className="second-button-modal-invite"
                onClick={() => editGroup()}
              >
                {t("btn.confirm")}
              </Button>
            </Flex>
            <Flex w="100%" alignItems="center" gap={4}>
              <Button
                className="danger-button"
                onClick={() => closeEditOpenAndOpenDelete()}
              >
                {t("btn.group.delete")}
              </Button>
              <Box
                display="flex"
                alignItems="center"
                fontSize="sm"
                color="var(--DANGER-COLOR)"
              >
                {t("group.delete.info")}
              </Box>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
