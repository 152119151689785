import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
  Input,
  FormControl,
  FormLabel,
  SimpleGrid,
  Box,
} from "@chakra-ui/react";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";

export default function ModalDeleteDescGroup({ isOpen, onClose, user }) {
  const { t } = useMarkdownTranslations();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        borderRadius="16px"
        maxWidth={{ base: "30%", sm: "80%", md: "30%", lg: "30%" }}
        padding={4}
      >
        <ModalBody paddingY={4}>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color="var(--DANGER-COLOR)"
              mb={0}
            >
              {t("group.are_you_sure")}
            </Text>
            <Text fontSize="sm" color="gray.600">
              {t("group.delete.warning")}
            </Text>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
            <FormControl
              gridColumn={{ base: "span 1", md: "span 3" }}
              position="relative"
            >
              <Box display="flex" justifyContent="space-between">
                <FormLabel htmlFor="group-name" className="input-label-texts">
                  {t("group.delete.instructions")}
                </FormLabel>
              </Box>
              <Input
                id="group-name"
                className="input-modal-invite-participants"
              />
            </FormControl>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter
          borderTop="1px"
          borderColor="gray.300"
          margin={0}
          pt={0}
          marginBottom={2}
        >
          <Box
            w="100%"
            display="flex"
            flexDirection="column"
            gap={6}
            marginTop={4}
          >
            <Flex w="100%" justifyContent="space-between" gap={4}>
              <Button
                className="first-button-modal-invite"
                onClick={() => onClose()}
              >
                {t("btn.go_back")}
              </Button>
              <Button
                className="second-button-modal-invite"
                onClick={() => onClose()}
              >
                {t("btn.confirm")}
              </Button>
            </Flex>
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
