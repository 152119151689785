import React from "react";
import { useState, useEffect } from "react";
import HostedDecisionsHeader from "../../components/HostedDecisionsHeader";
import {
  Box,
  Grid,
  Input,
  Text,
  Select,
  Button,
  Flex,
  InputGroup,
  InputRightElement,
  Image,
} from "@chakra-ui/react";
import "./styles.css";
import { useCurrentDecisionsContext } from "features/decisions/contexts/decisions-provider";
import DecisionCardHost from "components/DecisionCardHost";
import LeftArrow from "images/leftArrow";
import RightArrow from "images/rightArrow";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import searchIcon from "../../assets/images/search.png";
import searchDarkModeIcon from "../../assets/images/search_dark_mode.png";

export default function HostedDecisions({ lightMode, concatenateDecisions }) {
  const { tableDecisionsHostingData, tableDecisionsInvitedToHostData } =
    useCurrentDecisionsContext();
  const [listOfDecisions, setListOfDecisions] = useState(
    concatenateDecisions(
      tableDecisionsHostingData.dataOfTable,
      tableDecisionsInvitedToHostData.dataOfTable,
    ),
  );
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("All");
  const [sortBy, setSortBy] = useState("none");
  const { tMarkdown, t } = useMarkdownTranslations();
  const [listOfDecisionsAfterFilter, setListOfDecisionsAfterFilter] =
    useState(listOfDecisions);
  const [listOfDecisionsAfterSort, setListOfDecisionsAfterSort] =
    useState(listOfDecisions);
  const listOfStatus = [
    { label: t("decision.status.all"), value: "All" },
    { label: t("decision.status.open"), value: "Open" },
    { label: t("decision.status.unpublished"), value: "Unpublished" },
    { label: t("decision.status.coming_soon"), value: "Coming Soon" },
    { label: t("decision.status.finished"), value: "Finished" },
    { label: t("decision.status.shut_down"), value: "Shut Down" },
  ];
  const listOfSorts = [
    { label: t("decision.sort.by"), value: "none" },
    { label: t("decision.sort.title"), value: "title" },
    { label: t("decision.sort.url"), value: "url" },
    { label: t("decision.sort.status"), value: "status" },
    { label: t("decision.sort.open_date"), value: "open-date" },
    { label: t("decision.sort.close_date"), value: "close-date" },
  ];
  const [pageIndex, setPageIndex] = useState(0);
  const listOfPagesSizes = [6, 8, 10, 15, 20, 50];
  const [pageSize, setPageSize] = useState(listOfPagesSizes[0]);
  const [decisionsToShowWithPagination, setDecisionsToShowWithPagination] =
    useState(listOfDecisions);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getLowerNumber = (numberA, numberB) => {
    if (numberA < numberB) {
      return numberA;
    } else {
      return numberB;
    }
  };

  const previousPage = () => {
    if (pageIndex <= 0) return;
    setPageIndex(pageIndex - 1);
  };

  const nextPage = () => {
    if (listOfDecisionsAfterSort.length <= (pageIndex + 1) * pageSize) return;
    setPageIndex(pageIndex + 1);
  };

  const getPaginationButtons = (pagesNumber) => {
    const paginationButtons = () => {
      const buttonList = [];
      for (let i = 0; i < pagesNumber; i++) {
        buttonList.push(
          <Button
            className="button-change-page-decision-copy-list"
            color={pageIndex === i ? "var(--MAIN-TEXT)" : "var(--MAIN-GREY)"}
            bgColor={
              pageIndex === i ? "var(--MAIN-GREY)" : "var(--CLEAR-BACKGROUND)"
            }
            onClick={() => setPageIndex(i)}
            key={i}
          >
            {i + 1}
          </Button>,
        );
      }
      return buttonList;
    };

    return <>{paginationButtons()}</>;
  };

  useEffect(() => {
    let decisionsFiltered = listOfDecisions;

    if (search) {
      decisionsFiltered = decisionsFiltered.filter(
        (currentDecision) =>
          (currentDecision?.title &&
            currentDecision.title
              ?.toLowerCase()
              .includes(search?.toLowerCase())) ||
          (currentDecision?.links?.visit &&
            currentDecision.links.visit
              ?.toLowerCase()
              .includes(search?.toLowerCase())),
      );
    }

    if (status !== "All") {
      decisionsFiltered = decisionsFiltered.filter((currentDecision) =>
        currentDecision["current-mode"]
          .toLowerCase()
          .includes(status.toLowerCase()),
      );
    }

    setListOfDecisionsAfterFilter(decisionsFiltered);
  }, [search, status]);

  useEffect(() => {
    const decisionsCopy = [...listOfDecisionsAfterFilter];

    const decisionsSorted = decisionsCopy.sort(function (a, b) {
      switch (sortBy) {
        case "title":
          return a.title.localeCompare(b.title);
        case "url":
          return a.links.visit.localeCompare(b.links.visit);
        case "status":
          return a["current-mode"].localeCompare(b["current-mode"]);
        case "open-date":
          return new Date(b["open-at"]) - new Date(a["open-at"]);
        case "close-date":
          return new Date(b["close-at"]) - new Date(a["close-at"]);
        default:
          return 0;
      }
    });

    setListOfDecisionsAfterSort([...decisionsSorted]);
  }, [listOfDecisionsAfterFilter, sortBy]);

  useEffect(() => {
    setPageIndex(0);
  }, [pageSize]);

  useEffect(() => {
    const positionOfFirstTemplateToShow = pageIndex * pageSize;
    let positionOfLastDecisionToShow = getLowerNumber(
      listOfDecisionsAfterSort.length,
      (pageIndex + 1) * pageSize,
    );

    const newTemplatesToShow = listOfDecisionsAfterSort.slice(
      positionOfFirstTemplateToShow,
      positionOfLastDecisionToShow,
    );

    setDecisionsToShowWithPagination(newTemplatesToShow);
  }, [listOfDecisionsAfterSort, pageIndex, pageSize]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box className="main-box-hosted-decisions-page">
      <HostedDecisionsHeader lightMode={lightMode} />
      <Box className="body-box-hosted-decisions-page">
        <Flex className="flex-participate-participate-page" gap={4}>
          <Box className="first-box-participate-page">
            <InputGroup>
              <InputRightElement
                children={
                  <Image
                    src={lightMode ? searchIcon : searchDarkModeIcon}
                    className="input-right-element-image-create-decision-page"
                    alt={t("input.placeholder.search")}
                  />
                }
              />
              <Input
                className="input-participate-page"
                value={search || ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder={t("input.placeholder.search")}
              />
            </InputGroup>
          </Box>
          <Flex className="inside-flex-participate-page" gap={4}>
            <Box className="input-box-participate-page">
              <InputGroup>
                <Select
                  className="select-participate-page"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  {listOfStatus.map((currentStatus, i) => (
                    <option key={i} value={currentStatus.value}>
                      {currentStatus.label}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            </Box>
            <Box className="input-box-participate-page">
              <InputGroup>
                <Select
                  value={sortBy}
                  onChange={(e) => {
                    setSortBy(e.target.value);
                  }}
                  className="select-participate-page"
                >
                  {listOfSorts.map((currentSort, i) => (
                    <option key={i} value={currentSort.value}>
                      {currentSort.label}
                    </option>
                  ))}
                </Select>
              </InputGroup>
            </Box>
          </Flex>
        </Flex>

        <Grid className="grid-hosted-hosted-decisions-page" gap={3}>
          {windowWidth <= 768
            ? listOfDecisionsAfterSort.map((currentDecision, i) => (
                <DecisionCardHost key={i} currentDecision={currentDecision} />
              ))
            : decisionsToShowWithPagination.map((currentDecision, i) => (
                <DecisionCardHost key={i} currentDecision={currentDecision} />
              ))}
        </Grid>
        {windowWidth > 768 ? (
          <Flex
            className="outer-flex-hosted-decisions-page"
            role="region"
            aria-label={t("accessibility.pagination.arrows")}
          >
            <Flex className="column-flex-hosted-decisions-page">
              <Text className="showing-text-hosted-decisions-page">
                {t("pagination.show_pagination", {
                  from:
                    listOfDecisionsAfterSort.length > 0
                      ? pageIndex * pageSize + 1
                      : 0,
                  to: getLowerNumber(
                    listOfDecisionsAfterSort.length,
                    (pageIndex + 1) * pageSize,
                  ),
                  total: listOfDecisionsAfterSort.length,
                })}
              </Text>
            </Flex>
            <Flex className="flex-pagination-hosted-decisions-page">
              <Button
                className="button-arrow-page-hosted-decisions-page"
                cursor={pageIndex > 0 ? "pointer" : "not-allowed"}
                onClick={() => previousPage()}
              >
                <LeftArrow active={pageIndex > 0} />
              </Button>
              <Flex className="flex-pagination-buttons-hosted-decisions-page">
                {getPaginationButtons(
                  Math.ceil(listOfDecisionsAfterSort.length / pageSize),
                )}
              </Flex>
              <Flex
                onClick={() => nextPage()}
                className={"flex-change-page-hosted-decisions-page"}
                opacity={
                  pageIndex <
                  Math.ceil(listOfDecisionsAfterSort.length / pageSize) - 1
                    ? 1
                    : 0.84
                }
                _hover={{
                  cursor:
                    pageIndex <
                    Math.ceil(listOfDecisionsAfterSort.length / pageSize) - 1
                      ? "pointer"
                      : "not-allowed",
                }}
              >
                <Button
                  onClick={() => nextPage()}
                  className="button-arrow-page-hosted-decisions-page"
                  cursor={
                    pageIndex < listOfDecisionsAfterSort.length - 1
                      ? "pointer"
                      : "not-allowed"
                  }
                >
                  <RightArrow
                    active={pageIndex < listOfDecisionsAfterSort.length - 1}
                  />
                </Button>
              </Flex>
            </Flex>
            <Box></Box>
          </Flex>
        ) : (
          <Flex className="responsive-bottom-flex-hosted-decisions-page">
            <Flex
              onClick={() => previousPage()}
              className={`flex-change-page-hosted-decisions-page`}
              opacity={pageIndex > 0 ? 1 : 0.84}
              _hover={{ cursor: pageIndex > 0 ? "pointer" : "not-allowed" }}
            >
              <Button
                className="button-arrow-page-hosted-decisions-page"
                cursor={pageIndex > 0 ? "pointer" : "not-allowed"}
              >
                <LeftArrow active={pageIndex > 0} />
              </Button>
            </Flex>
            <Flex className="flex-pagination-buttons-hosted-decisions-page">
              {getPaginationButtons(
                Math.ceil(listOfDecisionsAfterSort.length / pageSize),
              )}
            </Flex>
            <Flex
              onClick={() => nextPage()}
              className={`flex-change-page-hosted-decisions-page ${
                pageIndex <
                Math.ceil(listOfDecisionsAfterSort.length / pageSize) - 1
                  ? "flex-change-page-opacity-total-hosted-decisions-page"
                  : "flex-change-page-opacity-partial-hosted-decisions-page"
              }`}
              opacity={
                pageIndex <
                Math.ceil(listOfDecisionsAfterSort.length / pageSize) - 1
                  ? 1
                  : 0.84
              }
              _hover={{
                cursor:
                  pageIndex <
                  Math.ceil(listOfDecisionsAfterSort.length / pageSize) - 1
                    ? "pointer"
                    : "not-allowed",
              }}
            >
              <Button
                className="button-arrow-page-hosted-decisions-page"
                cursor={
                  pageIndex < listOfDecisionsAfterSort.length - 1
                    ? "pointer"
                    : "not-allowed"
                }
              >
                <RightArrow
                  active={pageIndex < listOfDecisionsAfterSort.length - 1}
                />
              </Button>
            </Flex>
          </Flex>
        )}
      </Box>
    </Box>
  );
}
