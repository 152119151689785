import React from "react";

export default function rightArrow({ active }) {
  return (
    <svg
      width="5"
      height="10"
      viewBox="0 0 5 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 5.00001C5 5.40382 4.84298 5.80762 4.53475 6.11336L0.742952 9.87453C0.574297 10.0418 0.295145 10.0418 0.126491 9.87453C-0.0421636 9.70724 -0.0421636 9.43034 0.126491 9.26305L3.91828 5.50188C4.19744 5.22499 4.19744 4.77503 3.91828 4.49813L0.12649 0.736949C-0.042164 0.569656 -0.042164 0.29276 0.12649 0.125468C0.295145 -0.0418234 0.574297 -0.0418234 0.742951 0.125468L4.53475 3.88665C4.84298 4.19239 5 4.5962 5 5.00001Z"
        fill="var(--MAIN-GREY)"
        fillOpacity={active ? "1" : "0.94"}
      />
    </svg>
  );
}
