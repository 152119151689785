import React from "react";
import {
  Card,
  Flex,
  Box,
  Stack,
  CardBody,
  Heading,
  Text,
  Button,
  Link,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import useMarkdownTranslations from "../../translations/i18nextConfig/useMarkdownTranslations";
import "./styles.css";

export default function HostedDecisionHeader() {
  const { t } = useMarkdownTranslations();
  return (
    <Card className="card-hosted-decision-header" variant="outline">
      <Flex className="flex-center-hosted-decision-header">
        <Stack
          className="stack-hosted-decision-header"
          role="main"
          aria-label={t("accessibility.decision.create_card")}
          width="100%"
        >
          <CardBody className="card-body-hosted-decision-header">
            <Flex
              direction={{ base: "column", md: "row" }}
              alignItems="center"
              justify="space-between"
              gap={4}
              width="100%"
            >
              <Box>
                <Heading role="heading" aria-level="1" size="md">
                  {t("decision.hosting")}
                </Heading>
                <Text>{t("decision.access_all_decisions")}</Text>
              </Box>

              <Box
                display="flex"
                justifyContent={{ base: "center", md: "flex-end" }}
                width={{ base: "100%", md: "auto" }}
              >
                <Link as={RouterLink} to="/create-decisions" width="100%">
                  <Button
                    width={{ base: "100%", md: "auto" }}
                    className="button-hosted-decision-header"
                  >
                    {t("btn.decision.create_new")}
                  </Button>
                </Link>
              </Box>
            </Flex>
          </CardBody>
        </Stack>
      </Flex>
    </Card>
  );
}
